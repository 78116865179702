import React, { Fragment } from "react";

import { useQuery, gql, useMutation } from '@apollo/client';

import Header from './Header';
import Leftnav from './Leftnav';
import Appfooter from './Appfooter';

import Postview from './Postview';
import Load from './Load';
import { getImageUrl, getProfilePicture } from "../utils";
import Pagetitle from "./Pagetitle";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
        }
    }
`;
const UPSERT_POST_REACTION_MUTATION = gql`
    mutation UpsertPostReaction($id: UUID, $type: ReactionType, $userId: UUID) {
        upsert_PostReaction(postReaction:{ post: { id: $id }, type: $type, user: { id: $userId } }) {
            id
        }
    }
`;

const DELETE_POST_REACTION_MUTATION = gql`
    mutation DeletePostReaction($id: String!) {
        delete_PostReaction(id: $id)
    }
`;

const UPSERT_POST_COMMENT_MUTATION = gql`
    mutation UpsertPostComment($id: UUID, $content: String, $userId: UUID) {
        upsert_PostComment(postComment: {
            post: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

const UPSERT_COMMENT_REPLY_MUTATION = gql`
    mutation UpsertCommentReply($id: UUID, $content: String, $userId: UUID) {
        upsert_CommentReply(commentReply: {
            parentComment: { id: $id },
            user: { id: $userId },
            content: $content
        }) {
            id
        }
    }
`;

function FeedSearchResults({ posts, postsRefetch }) {

    const { data: viewerData, loading: viewerLoading, error: viewerError } = useQuery(GET_VIEWER_QUERY);    

    const [upsertPostReactionMutation ] = useMutation(UPSERT_POST_REACTION_MUTATION);
    const [deletePostReactionMutation ] = useMutation(DELETE_POST_REACTION_MUTATION);

    const [upsertPostCommentMutation ] = useMutation(UPSERT_POST_COMMENT_MUTATION);
    const [upsertCommentReplyMutation ] = useMutation(UPSERT_COMMENT_REPLY_MUTATION);

    //if (postsLoading && !postsData) return <LoadingOverlayWrapper/>;
    //if(viewerLoading) return <LoadingOverlayWrapper />;
    
    const handleLike = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LIKE',
                userId: viewerData.Viewer.id
            },
        })
        await postsRefetch({
            offset: 0,
            limit: posts.length || 0,
        });
    }

    const handleLove = async (id) => {
        await upsertPostReactionMutation({ 
            variables: {
                id,
                type: 'LOVE',
                userId: viewerData.Viewer.id
            },
        })
        await postsRefetch({
            offset: 0,
            limit: posts.length || 0,
        });
    }

    const handleDeleteReaction = async (reactionId) => {
        await deletePostReactionMutation({ 
            variables: {
                id: reactionId
            },
        })
        await postsRefetch({
            offset: 0,
            limit: posts.length || 0,
        });
    }

    const handleCreateComment = async (id, content) => {
        await upsertPostCommentMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await postsRefetch({
            offset: 0,
            limit: posts.length || 0,
        });
    }

    const handleCreateCommentReply = async (id, content) => {
        await upsertCommentReplyMutation({ 
            variables: {
                id,
                content,
                userId: viewerData.Viewer.id
            },
        })
        await postsRefetch({
            offset: 0,
            limit: posts.length || 0,
        });
    }

    const handlePostUpdated = async () => {
        await postsRefetch({
            offset: 0,
            limit: posts.length || 0,
        });
    }

    const sortedPosts = posts?.slice()
                    .sort(function (a, b) {
                        const d1 = new Date(a.createdDate).getTime()
                        const d2 = new Date(b.createdDate).getTime()
                        return d2 - d1;
                    }) || [];

    return (
        <Fragment>
            {sortedPosts.map((value , index) => (
                <Postview key={value.id} id={value.id} type={value.type} postvideo="" title={value.title} postimage={getImageUrl(value.thumbnail)} avater={getProfilePicture(value.user)} user={value.user} time={value.createdDate} des={value.content} pin={value.isPinned} allowComments={value.allowComments} comments={value.comments} attachments={value.attachments} handleCreateComment={handleCreateComment} handleCreateReply={handleCreateCommentReply} reactions={value.reactions} handleLike={() => handleLike(value.id)} handleLove={() => handleLove(value.id)} handleDeleteReaction={handleDeleteReaction} currentUser={viewerData && viewerData.Viewer.id} employee={value.employee} event={value.event} benefit={value.benefit} newsArticle={value.newsArticle} onPostUpdated={handlePostUpdated} galleryId={value?.mediaGallery?.id} surveyId={value?.survey?.id} />
            ))}
        </Fragment>
    );
}

export default FeedSearchResults;