import React, {useState, useEffect} from 'react';
import { getImageUrl, getProfilePicture } from '../utils';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm, Controller, useFieldArray } from "react-hook-form";

import WYSIWYGEditor from "./WYSIWYGEditor";

import CustomLoadingOverlay from "./CustomLoadingOverlay";

import { resizeImage } from "../utils/resize-image";

import { UPLOAD_IMAGE_ACCEPT_FILETYPES, UPLOAD_MAX_FILE_SIZE } from '../config';
import { RBACWrapper } from 'react-simple-rbac';

const UPSERT_POST_MUTATION = gql`
    mutation UpsertPost($type: PostType, $title: String, $content: String, $thumbnail: Upload, $isPinned: Boolean, $sendNotification: Boolean, $employee: inp_Employee, $event: inp_Event, $user: inp_User, $allowComments: Boolean, $mediaGallery: inp_MediaGallery, $attachments: [inp_PostAttachment], $survey: inp_Survey
        ) {
        upsert_Post(post: {
            type: $type,
            title: $title,
            content: $content,
            thumbnail: $thumbnail,
            isPinned: $isPinned,
            sendNotification: $sendNotification,
            employee: $employee,
            event: $event,
            user: $user,
            allowComments: $allowComments,
            mediaGallery: $mediaGallery,
            attachments: $attachments,
            survey: $survey,
        }) {
            id
        }
    }
`;

const UPSERT_POST_ATTACHMENT_MUTATION = gql`
    mutation UpsertPostAttachment($postId: UUID, $file: Upload) {
        upsert_PostAttachment(postAttachment: { post: { id: $postId }, file: $file }) {
            id
            name
        }
    }
`;

const GET_MEDIA_GALLERY_QUERY = gql`
    query Get($id: String!) {
        MediaGalleryById(id: $id) {
            id
            items {
                id
                name
                file
                displayOrder
            }
        }
    }
`;

const UPSERT_MEDIA_GALLERY_MUTATION = gql`
    mutation Upsert($name: String!) {
        upsert_MediaGallery(mediaGallery: { name: $name }) {
            id
        }
    }
`;

const DELETE_MEDIA_ITEM_MUTATION = gql`
    mutation Delete($id: String!) {
        delete_MediaItem(id: $id)
    }
`;

const UPSERT_MEDIA_ITEM_MUTATION = gql`
    mutation Upsert($galleryId: UUID, $file: Upload) {
        upsert_MediaItem(mediaItem: { gallery: { id: $galleryId }, file: $file }) {
            id
        }
    }
`;

const UPSERT_SURVEY_MUTATION = gql`
    mutation Upsert($title: String, $questions: [inp_Question]) {
        upsert_Survey(survey: { title: $title, questions: $questions }) {
            id
        }
    }
`;

function Createpost(props) {

    const [isUpsertLoading, setIsUpsertLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [isImageUploadOpen, setIsImageUploadOpen] = useState(false);
    const [isImageGalleryOpen, setIsImageGalleryOpen] = useState(false);
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);
    
    const [isAttachmentUploadOpen, setIsAttachmentUploadOpen] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [galleryId, setGalleryId] = useState(null);
    
    const [isSurveyOpen, setIsSurveyOpen] = useState(false);
    const [survey, setSurvey] = useState({ title: null, questions: [{}] });

    const { register, handleSubmit, watch, setValue, setError, formState: { errors }, clearErrors, reset, control } = useForm();
    
    const { register: registerSurvey, handleSubmit: handleSubmitSurvey, formState: { errors: errorsSurvey }, reset: resetSurvey, control: controlSurvey, getValues: getSurveyValues } = useForm();
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control: controlSurvey, // control props comes from useForm (optional: if you are using FormContext)
        name: "options", // unique name for your Field Array
        rules: { minLength: 2 },
    });

    const [upsertPostMutation, { upsertPostMutationData }] = useMutation(UPSERT_POST_MUTATION);
    const [upsertPostAttachmentMutation, { upsertPostAttachmentMutationData }] = useMutation(UPSERT_POST_ATTACHMENT_MUTATION);

    const { loading: loadingMediaGallery, error: errorMediaGallery, data: dataMediaGallery, refetch: refetchMediaGallery } = useQuery(GET_MEDIA_GALLERY_QUERY, {
        variables: { id: galleryId },
        skip: !galleryId
    });

    const [isUploadInProgress, setIsUploadInProgress] = useState(false)
    const [upsertMediaGalleryMutation, { upsertMediaGalleryMutationData }] = useMutation(UPSERT_MEDIA_GALLERY_MUTATION);
    const [upsertMediaItemMutation, { upsertMediaItemMutationData }] = useMutation(UPSERT_MEDIA_ITEM_MUTATION);
    const [deleteMediaItemMutation, { deleteMediaItemMutationData }] = useMutation(DELETE_MEDIA_ITEM_MUTATION);

    const [upsertSurveyMutation] = useMutation(UPSERT_SURVEY_MUTATION);

    useEffect(() => {
        append(0);
    }, [])

    const upsertPost = async (formData) => {
        setIsUpsertLoading(true)

        let thumbnail = selectedThumbnail;
        try {
            if(thumbnail) {
                thumbnail = await resizeImage(thumbnail, 720);
            }
        } catch (err) {
            console.log(err);
        }

        const surveyId = await upsertSurvey();
        
        const result = await upsertPostMutation({ 
            variables: {
                ...formData,
                thumbnail: thumbnail,
                user: { id: props.user.id },
                mediaGallery: galleryId ? { id: galleryId } : null,
                survey: surveyId ? { id: surveyId } : null
            },
        })

        if(result.data) {
            await Promise.all(attachments.map(async a => {
                await uploadAttachment(result.data.upsert_Post.id, a)
            }))

            props.onPostCreated && props.onPostCreated()
            toast.success("Publicado com sucesso!")

            reset()
            setSelectedThumbnail(null)
            setIsImageUploadOpen(false)
            setAttachments([])
            setIsAttachmentUploadOpen(false)
            setGalleryId(null)
            setIsImageGalleryOpen(false)
        } else {
            toast.error("Ocorreu um erro ao efetuar a publicação.")
        }
        setIsUpsertLoading(false)
    }

    const uploadAttachment = async (postId, file) => {
        const upsertResult = await upsertPostAttachmentMutation({ 
            variables: {
                postId: postId,
                file: file,
            },
        });
    }

    const onThumbnailFileChange = event => {
        setSelectedThumbnail(event.target.files[0])
    };

    const removeThumbnail = () => {
        setSelectedThumbnail(null)
        setIsImageUploadOpen(false)
    }

    const onAttachmentFileChange = event => {
        if(event.target.files[0]?.size > UPLOAD_MAX_FILE_SIZE) {
            toast.error('O ficheiro excede o tamanho permitido!')
            return;
        }

        setAttachments(prevState => [...prevState, event.target.files[0]])
    };

    const removeAttachment = name => {
        setAttachments(prevState => prevState.filter(a => a?.name !== name))
    }

    const deleteMediaItem = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a imagem?')) {
            const deleteResult = await deleteMediaItemMutation({ 
                variables: {
                    id
                },
            })

            if(deleteResult.data) {
                toast.success('Imagem eliminada com sucesso!')
                refetchMediaGallery()
            }
        }
    }

    const onMediaGalleryFileChange = async (event) => { 
        setIsUploadInProgress(true);

        let gId = galleryId;

        if(!galleryId) {
            const upsertGalleryResult = await upsertMediaGalleryMutation({ 
                variables: {
                    name: 'post-gallery',
                },
            });
    
            if(upsertGalleryResult.data) {
                gId = upsertGalleryResult.data.upsert_MediaGallery.id
                setGalleryId(gId)
    
            } else {
                toast.error('Ocorreu um erro ao adicionar a imagem!')
            }
        }

        let file = event.target.files[0];
        try {
            file = await resizeImage(file, 1280);
        } catch (err) {
            console.log(err);
        }

        if(file?.size > UPLOAD_MAX_FILE_SIZE) {
            toast.error('A imagem excede o tamanho permitido!')
            setIsUploadInProgress(false);
            return;
        }

        const upsertResult = await upsertMediaItemMutation({ 
            variables: {
                galleryId: gId,
                file: file
            },
        });

        if(upsertResult.data) {
            toast.success('Imagem adicionada com sucesso!')
            await refetchMediaGallery();
        } else {
            toast.error('Ocorreu um erro ao adicionar a imagem!')
        }
        setIsUploadInProgress(false)
    };

    const upsertSurvey = async () => {
        const survey = getSurveyValues();

        console.log(survey)

        if(!survey?.options || survey?.options?.length < 2) {
            return null;
        }

        let options = survey.options?.filter(e => e.value?.trim().length > 0).map((e, index) => ({ value: e.value, displayOrder: index}))
        const question = `{questionText: ${JSON.stringify(survey.question)}, type: DROPDOWN, displayOrder: 0, options: ${JSON.stringify(options)}}`

        const upsertResult = await upsertSurveyMutation({ 
            variables: {
                title: 'post-survey',
                questions: [{questionText: survey.question, type: "DROPDOWN", displayOrder: 0, options: options}]
            },
        });

        resetSurvey()

        if(upsertResult.data) {
            return upsertResult.data.upsert_Survey.id
        } else {
            toast.error('Ocorreu um erro ao adicionar a sondagem!')
            return null;
        }
    }

    const toggleOpen = () => setIsOpen(!isOpen);
    const toggleImageUploadOpen = () => setIsImageUploadOpen(!isImageUploadOpen);
    const toggleImageGalleryOpen = () => setIsImageGalleryOpen(!isImageGalleryOpen);
    const toggleAttachmentUploadOpen = () => setIsAttachmentUploadOpen(!isAttachmentUploadOpen);
    const toggleSurveyOpen = () => setIsSurveyOpen(!isSurveyOpen);

    const menuClass = `${isOpen ? " show" : ""}`;

    const galleryImages = dataMediaGallery?.MediaGalleryById?.items || [];

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3" style={{minHeight: '150px'}}>
            <form onSubmit={handleSubmit(upsertPost)}>
                <div className="card-body p-0">
                    <span className="font-xssss fw-600 text-grey-500 card-body p-0 d-flex align-items-center"><i className="btn-round-sm font-xs text-success feather-edit-3 me-2 bg-greylight"></i>Nova Publicação</span>
                </div>
                <CustomLoadingOverlay active={isUpsertLoading}>
                <div className="card-body p-0 mt-3">
                    <input name="message" className="bor-0 w-100 rounded-xxxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" placeholder="Título (opcional)" {...register("title")} />
                </div>
                <div className="card-body p-0 mt-2 position-relative">
                    {false && <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src={getProfilePicture(props?.user)} alt="icon" className="shadow-sm rounded-circle w30" /></figure>}
                    { /* <textarea name="message" className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="10" placeholder="Escreve aqui a tua mensagem..." {...register("content", { required: true })}></textarea> */ }
                    <Controller
                        render={({ field }) => <WYSIWYGEditor {...field} editorStyle={{maxHeight: '400px', border: '2px #eee solid'}} editorClassName="h100 bor-0 w-100 rounded-xxl p-1 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg" />}
                        name="content"
                        control={control}
                        //defaultValue=""
                        /*rules={{
                            validate: {
                            required: (v) =>
                                (v && stripHtml(v).result.length > 0) ||
                                "Description is required",
                            maxLength: (v) =>
                                (v && stripHtml(v).result.length <= 2000) ||
                                "Maximum character limit is 2000",
                            },
                        }}*/
                    />
                    {errors.content && <span className="text-red">Este campo é obrigatório</span>}
                </div>
                <div className="card-body p-0 mt-1">
                    <RBACWrapper requiredRoles={['admin']} oneOf>
                    <div className="form-check mt-1 mb-2">
                        <input className="form-check-input" type="checkbox" id="isPinned" {...register("isPinned")} />
                        <label className="font-xssss fw-600 ls-1 text-grey-700" htmlFor="isPinned">Afixar publicação</label>
                    </div>
                    </RBACWrapper>

                    <div className="form-check mt-1 mb-2">
                        <input className="form-check-input" type="checkbox" id="allowComments" {...register("allowComments")} />
                        <label className="font-xssss fw-600 ls-1 text-grey-700" htmlFor="allowComments">Permitir comentários</label>
                    </div>

                    <div className="form-check mt-1 mb-2">
                        <input className="form-check-input" type="checkbox" id="sendNotification" {...register("sendNotification")} />
                        <label className="font-xssss fw-600 ls-1 text-grey-700" htmlFor="sendNotification">Notificar todos os utilizadores</label>
                    </div>

                    {isImageUploadOpen &&
                    <div>
                        <label className="font-xssss fw-600 ls-1 text-grey-700 mb-1">Imagem {selectedThumbnail && <a role="button" onClick={removeThumbnail}><i className="font-xss text-danger feather-trash ms-1"></i></a>}</label>
                        <input type="file" name="file" id="file" className="input-file" onChange={onThumbnailFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                        <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed mb-2">
                        <i className={`${selectedThumbnail ? 'ti-image' : 'ti-cloud-down'} large-icon me-3 d-block`}></i>
                        <span className="js-fileName">{ selectedThumbnail ? selectedThumbnail.name : 'Clique para selecionar uma imagem' }</span>
                        </label>
                    </div>
                    }

                    {(attachments.length > 0 || isAttachmentUploadOpen) &&
                    <div>
                        <label className="font-xsss fw-600 ls-1 text-grey-700">Anexos</label>
                        <ul className="mb-1 font-xssss fw-600 ls-1 text-grey-700">
                            {attachments.map((a, index) => (
                                <li key={`attachment-${index}`}><i className="font-xsss text-grey-500 feather-paperclip me-1"></i> {a?.name}<a role="button" onClick={() => removeAttachment(a?.name)}><i className="font-xss text-danger feather-trash ms-1"></i></a></li>
                            ))}
                        </ul>
                    </div>
                    }

                    {isAttachmentUploadOpen &&
                    <div>
                        <input type="file" name="attachmentFile" id="attachmentFile" className="input-file" onChange={onAttachmentFileChange} />
                        <label htmlFor="attachmentFile" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed mb-2">
                        <i className={`ti-cloud-down large-icon me-3 d-block`}></i>
                        <span className="js-fileName">Clique para selecionar um ficheiro</span>
                        </label>
                    </div>
                    }

                    {(galleryImages.length > 0 || isImageGalleryOpen) &&
                    <div>
                        <label className="font-xsss fw-600 ls-1 text-grey-700">Galeria</label>
                        <div className="row">
                            { isUploadInProgress && <div className="col-3 pe-2 ps-2">
                                <CustomLoadingOverlay active={true} />
                            </div>}
                            
                            {galleryImages?.slice().sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map((value , index) => (
                                <div key={index} class="col-3 mb-2 pe-1">
                                    <a role="button" onClick={() => deleteMediaItem(value.id)}>
                                        <img src={getImageUrl(value.file)} alt="" class="img-fluid rounded-3 w-100 aspect-ratio-285-160" style={{objectFit: 'cover'}} />
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    }

                    {isImageGalleryOpen &&
                    <div>
                        <input type="file" name="galleryFile" id="galleryFile" className="input-file" onChange={onMediaGalleryFileChange} accept={UPLOAD_IMAGE_ACCEPT_FILETYPES} />
                        <label htmlFor="galleryFile" className="rounded-3 text-center bg-white btn-tertiary js-labelFile w-100 border-dashed mb-2">
                        <i className={`ti-image large-icon me-3 d-block`}></i>
                        <span className="js-fileName">Clique para adicionar uma imagem</span>
                        </label>
                    </div>
                    }

                    {isSurveyOpen &&
                    <div className="mt-3 mb-3">
                        <label className="font-xsss fw-600 ls-1 text-grey-700">Sondagem</label>
                        <div className="card-body p-0">
                            <label className="font-xssss fw-600 ls-1 text-grey-700">Pergunta</label>
                            <input className="bor-0 w-100 rounded-xxxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg mt-1" autoComplete="off" placeholder="Pergunta" {...registerSurvey("question")} />
                        </div>
                        <div className="card-body p-0 mt-3">
                            <label className="font-xssss fw-600 ls-1 text-grey-700">Opções</label>
                            {fields.map((field, index) => (
                                <input
                                    key={field.id} // important to include key with field's id
                                    className="bor-0 w-100 rounded-xxxl p-2 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg mt-1"
                                    autoComplete="off"
                                    placeholder={`Opção ${index+1}`}
                                    {...registerSurvey(`options.${index}.value`, {
                                        onChange: (e) => {
                                            if(e.target.value?.trim().length > 0 && (index + 1) >= fields?.length) {
                                                append({}, {shouldFocus: false})
                                            }

                                            if(e.target.value?.trim().length == 0 && (!fields[index+1]?.value || fields[index+1]?.value?.trim().length == 0)) {
                                                remove(index + 1)
                                            }
                                        }
                                    })}
                                />
                            ))}
                        </div>
                    </div>
                    }
                </div>
                <div className="card-body d-flex p-0 mt-0">
                    { false && <a href="#video" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 pointer"><i className="font-md text-danger feather-video me-2"></i><span className="d-none-xs">Live Video</span></a> }
                    <a button="role" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 pointer" onClick={toggleImageUploadOpen}><i className="font-md text-primary feather-image me-2"></i><span className="d-none-xs">Imagem</span></a>
                    <a button="role" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 pointer" onClick={toggleImageGalleryOpen}><i className="font-md text-primary feather-grid me-2"></i><span className="d-none-xs">Galeria</span></a>
                    <a button="role" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 pointer" onClick={toggleAttachmentUploadOpen}><i className="font-md text-secondary feather-paperclip me-2"></i><span className="d-none-xs">Anexos { attachments.length > 0 && `(${attachments.length})`}</span></a>
                    <a button="role" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 pointer" onClick={toggleSurveyOpen}><i className="font-md text-warning feather-list me-2"></i><span className="d-none-xs">Sondagem</span></a>
                    { false && <a href="#activity" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4 pointer"><i className="font-md text-warning feather-camera me-2"></i><span className="d-none-xs">Feeling/Activity</span></a> }
                    { false && <>
                    <div className={`ms-auto pointer ${menuClass}`} id="dropdownMenu4" data-bs-toggle="dropdown" aria-expanded="false" onClick={toggleOpen}><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div>
                    <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby="dropdownMenu4">
                        <div className="card-body p-0 d-flex">
                            <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Save Link <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Add this to your saved items</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Hide Post <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Hide all from Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                        <div className="card-body p-0 d-flex mt-2">
                            <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                            <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-4 pointer">Unfollow Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                        </div>
                    </div>
                    </>}
                    <a role="button" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark ps-2 pe-2 ms-auto" onClick={handleSubmit(upsertPost)}><i className="font-md text-success feather-send me-2"></i><span className="d-none-xs">Publicar</span></a>
                </div>
                </CustomLoadingOverlay>
            </form>
        </div>
    );
}

export default Createpost;