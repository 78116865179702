import React, { Fragment } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { getProfilePicture } from "../../utils";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import FeedSearchHeader from "../../components/FeedSearchHeader";
import { GRAPHQL_MAX_RESULTS_LIMIT } from "../../config";

const GET_EMPLOYEES_QUERY = gql`
    query GetEmployees {
        EmployeeList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}, filter: { isActive: { _eq: true } }) {
            id
            name
            birthdate
            email
            jobTitle
            picture
            user {
                id
                picture
            }
        }
    }
`;

function EmployeeList() {
    
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data } = useQuery(GET_EMPLOYEES_QUERY);

    const employees = (data && data.EmployeeList.slice().sort((a, b) => a.name.localeCompare(b.name))) || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        <div className="row">
                            <div className="col-xl-12">
                                
                                <Pagetitle title={`${ !searchParams?.get("q") ? "Colaboradores" : `Resultados da Pesquisa - ${searchParams?.get("q")}` }`} customButton={{link: '/employees/orgChart', icon: 'feather-layers'}} color="success"/>

                                { searchParams?.get("q") && <FeedSearchHeader currentPage="employees" searchQuery={searchParams?.get("q")} /> }
                                
                                <CustomLoadingOverlay active={loading}/>

                                <div className="row ps-2 pe-1">
                                    {employees.filter(e => !searchParams.get("q") || e.name.toLowerCase().includes(searchParams.get("q").toLowerCase().trim())).map((value , index) => (

                                    <div key={index} className="col-md-4 col-sm-6 pe-2 ps-2 cursor-pointer" onClick={() => navigate(`/employees/${value.id}`)}>
                                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                                            <div className="card-body d-block w-100 p-4 text-center">
                                                <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1"><img src={getProfilePicture(value)} alt={value.name} className="float-right p-1 bg-white rounded-circle w-100 aspect-ratio-1" /></figure>
                                                <div className="clearfix"></div>
                                                <h4 className="fw-700 font-xss mt-3 mb-0">{value.name} </h4>
                                                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3" style={{lineHeight: "inherit"}}>{value.jobTitle || '-'}</p>
                                                { false &&
                                                <ul className="d-flex align-items-center justify-content-center mt-1">
                                                    <li className="m-2"><h4 className="fw-700 font-sm">{value.connections} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">Connections</span></h4></li>
                                                    <li className="m-2"><h4 className="fw-700 font-sm">{value.follower} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">Follower</span></h4></li>
                                                    <li className="m-2"><h4 className="fw-700 font-sm">{value.following} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">Followings</span></h4></li>
                                                </ul>
                                                }
                                                <ul className="d-flex align-items-center justify-content-center mt-1">
                                                    {value.badge1 ? <li className="m-1"><img src={`assets/images/${value.badge1}`} alt="icon" /></li> : ''}
                                                    {value.badge2 ? <li className="m-1"><img src={`assets/images/${value.badge2}`} alt="icon" /></li> : ''}
                                                    {value.badge3 ? <li className="m-1"><img src={`assets/images/${value.badge3}`} alt="icon" /></li> : ''}
                                                    {value.badge4 ? <li className="m-1"><img src={`assets/images/${value.badge4}`} alt="icon" /></li> : ''}
                                                </ul>
                                                { false && <a href="#follow" className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">FOLLOW</a> }
                                            </div>
                                        </div>
                                    </div>

                                    ))}

                                    
                                </div>
                            </div>               
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

export default EmployeeList;