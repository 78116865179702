import React, { Fragment } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";

import { useQuery, gql } from '@apollo/client';

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Pagetitle from '../../components/Pagetitle';
import Appfooter from '../../components/Appfooter';
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { getImageUrl, getProfilePicture } from "../../utils";
import CustomLoadingOverlay from "../../components/CustomLoadingOverlay";
import FeedSearchHeader from "../../components/FeedSearchHeader";
import { GRAPHQL_MAX_RESULTS_LIMIT, config } from "../../config";

import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const GET_COMPANIES_QUERY = gql`
    query GetCompanies {
        CompanyList(limit: ${GRAPHQL_MAX_RESULTS_LIMIT}) {
            id
            name
            description
            vatId
            logo
            industry {
                name
            }
            website
            email
            phone
            mobile
            city {
                id
                name
            }
            address
            zipCode
            latitude
            longitude
        }
    }
`;

const defaultMapProps = {
    center: {
        lat: 59.95,
        lng: 30.33
    },
    zoom: 11
};

function CompanyList() {
    
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { loading, error, data } = useQuery(GET_COMPANIES_QUERY);

    const companies = (data && data.CompanyList.slice().sort((a, b) => a.name.localeCompare(b.name))) || [];

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" style={{maxWidth: "100%"}}>
                        <div className="row">
                            <div className="col-xl-6 chat-left scroll-bar">
                                <Pagetitle title={`${ !searchParams?.get("q") ? "Empresas" : `Resultados da Pesquisa - ${searchParams?.get("q")}` }`} color="success"/>
                                <div className="row ps-2 pe-2">
                                    { searchParams?.get("q") && <FeedSearchHeader currentPage="companies" searchQuery={searchParams?.get("q")} /> }

                                    { companies.filter(
                                        e => !searchParams.get("q")
                                                || e.name?.toLowerCase().includes(searchParams.get("q").toLowerCase().trim())
                                                || e.description?.toLowerCase().includes(searchParams.get("q").toLowerCase().trim())
                                        ).map((value , index) => (

                                        <div key={index} className="col-lg-4 col-md-4 col-sm-6 mb-3 pe-2 ps-2">
                                            <div className="card w-100 p-0 hover-card shadow-xss border-0 rounded-3 overflow-hidden me-1">
                                                {false && value.feature ? <span className="font-xsssss fw-700 ps-3 pe-3 lh-32 text-uppercase rounded-3 ls-2 bg-primary-gradiant d-inline-block text-white position-absolute mt-3 ms-3 z-index-1">Featured</span> : ''}
                                                <div className="card-image w-100 mb-3">
                                                    <Link to={`/companies/${value.id}`} className="position-relative d-block"><img src={getImageUrl(value.logo, "/assets/images/unknown-company-icon.png")} alt={value.name} className="w-100" /></Link>
                                                </div>
                                                <div className="card-body pt-0">
                                                    {false && <i className="feather-bookmark font-md text-grey-500 position-absolute right-0 me-3"></i> }
                                                    <h4 className="fw-700 font-xss mt-0 lh-28 mb-0"><a href="default-hotel-details.html" className="text-dark text-grey-900">{value.name}</a></h4>
                                                    <h6 className="font-xsssss text-grey-500 fw-600 mt-0 mb-2"> {value.city?.name}</h6>
                                                    { false && <div className="star d-block w-100 text-left mt-0">
                                                        <img src="assets/images/star.png" alt="star" className="w15 me-1 float-left" />
                                                        <img src="assets/images/star.png" alt="star" className="w15 me-1 float-left" />
                                                        <img src="assets/images/star.png" alt="star" className="w15 me-1 float-left" />
                                                        <img src="assets/images/star.png" alt="star" className="w15 me-1 float-left" />
                                                        <img src="assets/images/star-disable.png" alt="star" className="w15 me-1 float-left me-2" />
                                                    </div> }
                                                    { false && <div className="mt-4 w-100"></div> }
                                                    <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500 me-2">{value.website && <a href={value.website} target="_blank" rel="noopener"><i className="btn-round-sm bg-greylight ti-link text-grey-500 me-1"></i></a> }</h5>
                                                    {false && <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500 me-2"><i className="btn-round-sm bg-greylight ti-rss-alt text-grey-500 me-1"></i> WiFi</h5> }
                                                    {false && <h5 className="mt-3 d-inline-block font-xssss fw-600 text-grey-500"><i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i> Card</h5> }
                                                    <div className="clearfix"></div>
                                                    { value.industry && <span className="font-lg fw-700 mt-0 pe-3 ls-2 lh-32 d-inline-block text-success float-left"><span className="font-xsssss">$</span> {value.industry?.name} </span> }
                                                    <Link to={`/companies/${value.id}`} className="position-absolute bottom-15 mb-2 right-15"><i className="btn-round-sm bg-primary-gradiant text-white font-sm feather-chevron-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </div>

                                
                            </div>

                            <div className="col-xl-6 ps-0 d-none d-xl-block">
                                <div className="card w-100 border-0 shadow-none rounded-3 border-0 mb-4 overflow-hidden ">
                                    { false && <div style={{ height: '86vh', width: '100%' }}>
                                        <GoogleMapReact
                                        defaultCenter={defaultMapProps.center}
                                        defaultZoom={defaultMapProps.zoom}
                                        bootstrapURLKeys={{ key: config.GOOGLE_MAPS_API_KEY }}
                                        >
                                        <AnyReactComponent
                                            lat={59.955413}
                                            lng={30.337844}
                                            text="My Marker"
                                        />
                                        </GoogleMapReact>
                                    </div> }
                                    <div className="card border-0 text-center d-block p-0">
                                        <img src="/assets/images/bg/chat.jpg" className="w-100 mb-2 ms-auto me-auto pt-md-5" />
                                        <h1 className="fw-700 text-grey-900 display2-size display4-md-size">empathize Chat</h1>
                                        {false && <p className="text-grey-500 font-xsss">A página que procura não existe</p> }
                                        <a href="#" className="p-3 w175 bg-current text-white d-inline-block text-center fw-600 font-xssss rounded-3 text-uppercase ls-3 mt-3 mb-5">Brevemente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Appfooter /> 
        </Fragment>
    );
}

export default CompanyList;