import React, { Fragment, useRef, useState, useContext, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import Moment from "react-moment";

import { Modal } from "react-bootstrap";

import AdminHeader from '../../../components/AdminHeader';
import Leftnav from '../../../components/Leftnav';
import Appfooter from '../../../components/Appfooter';

import CustomLoadingOverlay from "../../../components/CustomLoadingOverlay";
import { PERSON_GENDER_OPTIONS, TRAINING_ACTION_STATUS, USER_ROLES } from "../../../config";
import { getProfilePicture } from "../../../utils";
import CreditCard from "../../../components/CreditCard";
import { useAuth } from "../../../providers/authProvider";

const GET_QUERY = gql`
    query Get($id: String!) {
        EmployeeById(id: $id) {
            id
            externalId
            fullName
            name
            birthdate
            gender
            email
            upn
            phone
            mobile
            mobilePersonal
            company {
                id
                name
            }
            department {
                id
                name
            }
            jobTitle
            picture
            isExternal
            hideFromOrgChart
            isActive
            hireDate
            terminationDate
            user {
                id
                externalId
                username
                email
                picture
                isActive
                createdDate
                wallet {
                    id
                    balance
                    transactions {
                        id
                        amount
                        finalBalance
                        completedDate
                        description
                        createdDate
                    }
                }
            }
            manager {
                id
                name
            }
            directReports {
                id
                fullName
                name
                company {
                    id
                    name
                }
                department {
                    id
                    name
                }
                jobTitle
                picture
                isExternal
                isActive
                user {
                    id
                    picture
                }
            }
            skills {
                id
                skill {
                    id
                    name
                    description
                }
            }
        }
    }
`;

const GET_USER_IDENTITY_QUERY = gql`
    query Get($id: UUID!) {
        UserIdentity(userId: $id) {
            id
            username
            isEnabled
            isEmailVerified
            firstName
            lastName
            email
            federationLink
            serviceAccountClientId
            realmRoles {
                id
            }
            groups {
                id
            }
            createdTimestamp
        }
    }
`;

const GET_EXISTING_POST_QUERY = gql`
    query Get($id: UUID!) {
        PostList(filter: { AND: [{ type: { _eq: EMPLOYEE_ENTRY }}, {employee: { id: { _eq: $id} } }] }) {
            id
        }
    }
`;

const UPSERT_EMPLOYEE_STATUS_MUTATION = gql`
    mutation Upsert($id: UUID, $isActive: Boolean!) {
        upsert_Employee(employee:{ id: $id, isActive: $isActive }) {
            id
        }
    }
`;

const GET_LISTS_QUERY = gql`
    query GetLists {
        SkillList(orderBy: { name: ASC }) {
            id
            name
        }
    }
`;

const UPDATE_USER_PASSWORD_MUTATION = gql`
    mutation UpdateUserPassword($userId: UUID, $newPassword: String!) {
        UpdateUserPassword(userId: $userId, newPassword: $newPassword )
    }
`;

const UPDATE_USER_ROLE_MUTATION = gql`
    mutation UpdateUserRole($userId: UUID, $role: String!) {
        UpdateUserRole(userId: $userId, role: $role )
    }
`;

const TOPUP_WALLET_MUTATION = gql`
    mutation TopupWallet($id: UUID, $amount: Float!) {
        TopupWallet(id: $id, amount: $amount) {
            id
            balance
        }
    }
`;

const CREATE_NEW_EMPLOYEE_POST_MUTATION = gql`
    mutation CreateNewEmployeePost($employeeId: UUID, $content: String) {
        CreateNewEmployeePost(employeeId: $employeeId, content: $content) {
            id
        }
    }
`;

const DELETE_EMPLOYEE_SKILL_MUTATION = gql`
    mutation DeleteEmployeeSkill($id: String!) {
        delete_EmployeeSkill(id: $id)
    }
`;

const UPSERT_EMPLOYEE_SKILL_MUTATION = gql`
    mutation Upsert($employeeId: UUID, $skill: inp_Skill) {
        upsert_EmployeeSkill(employeeSkill: { employee: { id: $employeeId }, skill: $skill } ) {
            id
        }
    }
`;


const UpdatePasswordModal = (props) => {

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors, reset } = useForm();
    let newPassword = watch("newPassword");

    const [updateUserPasswordMutation, { updateUserPasswordMutationData }] = useMutation(UPDATE_USER_PASSWORD_MUTATION);

    const updateUserPassword = async (formData) => {
        const upsertResult = await updateUserPasswordMutation({ 
            variables: {
                userId: props.user?.id,
                ...formData
            },
        });

        if(upsertResult.data) {
            reset();
            toast.success('Alterações guardadas!');
            props.onHide();
        }
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Alterar Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(updateUserPassword)}>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">Nova Password</label>
                            <input type="password" className="form-control" autoComplete="off" {...register("newPassword", {
                                required: "Este campo é obrigatório",
                                minLength: {
                                    value: 8,
                                    message: "A password deve ter pelo menos 8 caracteres"
                                }
                            })} />
                            <span className="text-red">{errors?.newPassword?.message}</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">Confirmar Nova Password</label>
                            <input type="password" className="form-control" autoComplete="off" {...register("confirmNewPassword", {
                                required: true,
                                validate: value => value === newPassword || "As passwords não coincidem"
                            })} />
                            <span className="text-red">{errors?.confirmNewPassword?.message}</span>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <a role="button" className="bg-grey text-center text-dark font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Cancelar</a>
            <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={handleSubmit(updateUserPassword)}>Continuar</a>
        </Modal.Footer>
      </Modal>
    );
}

const UpdateRoleModal = (props) => {

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [updateUserRoleMutation, { updateUserRoleMutationData }] = useMutation(UPDATE_USER_ROLE_MUTATION);

    const updateUserRole = async (formData) => {
        const upsertResult = await updateUserRoleMutation({ 
            variables: {
                userId: props.user?.id,
                ...formData
            },
        });

        if(upsertResult.data) {
            reset();
            toast.success('Alterações guardadas!');
            props.onHide();
        }
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Alterar Perfil
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(updateUserRole)}>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">Perfil</label>
                            <select className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("role", { required: true })}>
                                <option value=''>Selecionar...</option>
                                {
                                    USER_ROLES.map((e) => {
                                        return (
                                            <option key={e.id} value={e.id}>{e.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.role && <span className="text-red">Este campo é obrigatório</span>}
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <a role="button" className="bg-grey text-center text-dark font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Cancelar</a>
            <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={handleSubmit(updateUserRole)}>Continuar</a>
        </Modal.Footer>
      </Modal>
    );
}

const AddEmployeeSkillModal = (props) => {

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors, reset } = useForm();

    const { data: listsData, loading: listsLoading, error: listsError } = useQuery(GET_LISTS_QUERY);
    const [upsertEmployeeSkillMutation] = useMutation(UPSERT_EMPLOYEE_SKILL_MUTATION);

    const upsertEmployeeSkill = async (formData) => {
        const upsertResult = await upsertEmployeeSkillMutation({ 
            variables: {
                employeeId: props.employee?.id,
                ...formData
            },
        });

        if(upsertResult.data) {
            reset();
            toast.success('Alterações guardadas!');
            props.onHide();
        }
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Adicionar Competência
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(upsertEmployeeSkill)}>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">Competência</label>
                            <select className="form-control mb-0 pt-0 pv-0" defaultValue='' {...register("skill.id", { required: true })}>
                                <option value=''>Selecionar...</option>
                                {
                                    listsData?.SkillList?.slice().sort((a, b) => a.name.localeCompare(b.name)).map((e) => {
                                        return (
                                            <option key={e.id} value={e.id}>{e.name}</option>
                                        )
                                    })
                                }
                            </select>
                            {errors.role && <span className="text-red">Este campo é obrigatório</span>}
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <a role="button" className="bg-grey text-center text-dark font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Cancelar</a>
            <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={handleSubmit(upsertEmployeeSkill)}>Adicionar</a>
        </Modal.Footer>
      </Modal>
    );
}

const TopupWalletModal = (props) => {

    const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors, reset } = useForm();

    const [topupWalletMutation, { topupWalletMutationData }] = useMutation(TOPUP_WALLET_MUTATION);

    const topupWallet = async (formData) => {
        const upsertResult = await topupWalletMutation({ 
            variables: {
                id: props.user?.wallet?.id,
                ...formData
            },
        });

        if(upsertResult.data) {
            reset();
            toast.success(`Valor adicionado. Saldo final: ${upsertResult.data.TopupWallet.balance}`);
            props.onHide();
        }
    }

    console.log(errors)

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"className="fw-700">
            Adicionar Saldo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit(topupWallet)}>
                <div className="row">
                    <div className="col-lg-12 mb-3">
                        <div className="form-group">
                            <label className="mont-font fw-600 font-xsss">Valor</label>
                            <input type="number" min={0} step={1} className="form-control" autoComplete="off" {...register("amount", {
                                required: "Este campo é obrigatório",
                                valueAsNumber: true,
                                validate: (value) => value > 0,
                            })} />
                            <span className="text-red">{errors?.amount?.message}</span>
                            {errors?.amount?.type === "validate" && <span className="text-red">O valor deve ser superior a 0</span> }
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <a role="button" className="bg-grey text-center text-dark font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={props.onHide}>Cancelar</a>
            <a role="button" className="bg-current text-center text-white font-xsss fw-600 p-2 rounded-3 d-inline-block w-auto me-3" onClick={handleSubmit(topupWallet)}>Adicionar</a>
        </Modal.Footer>
      </Modal>
    );
}

function AdminEmployee() {

    let { id } = useParams();
    
    const [currentUser, setCurrentUser] = useState(null);
    
    const auth = useAuth()
    
    useEffect(() => {
        if(auth.isAuthenticated()) {
            auth.getUser().then(u => {
                setCurrentUser(u?.profile)
            })
        }
    }, [auth])

    const [passwordModalShow, setPasswordModalShow] = useState(false);
    const [roleModalShow, setRoleModalShow] = useState(false);
    const [topupWalletModalShow, setTopupWalletModalShow] = useState(false);
    const [addSkillModalShow, setAddSkillModalShow] = useState(false);

    const { data, loading, error, refetch } = useQuery(GET_QUERY, { variables: { id: id }, skip: !id});
    const { data: userData, loading: userLoading, error: userError, refetch: userRefetch } = useQuery(GET_USER_IDENTITY_QUERY, {
        variables: { id: data?.EmployeeById?.user?.id },
        skip: !data?.EmployeeById?.user
    });
    const { data: existingPostData, loading: existingPostLoading, error: existingPostError, refetch: existingPostRefetch } = useQuery(GET_EXISTING_POST_QUERY, { variables: { id: id }, skip: !id});

    const [upsertEmployeeStatusMutation] = useMutation(UPSERT_EMPLOYEE_STATUS_MUTATION);
    const [createNewEmployeePostMutation] = useMutation(CREATE_NEW_EMPLOYEE_POST_MUTATION);
    const [deleteEmployeeSkillMutation] = useMutation(DELETE_EMPLOYEE_SKILL_MUTATION);

    const updateEmployeeStatus = async (isActive) => {
        const upsertResult = await upsertEmployeeStatusMutation({ 
            variables: {
                id,
                isActive
            },
        })
        await refetch();

        if(upsertResult.data) {
            toast.success(`Colaborador ${isActive ? 'ativado' : 'desativado'}`)
        }
    }

    const createNewEmployeePost = async (employeeId, content) => {
        if(window.confirm('Tem a certeza que pretende publicar a entrada do colaborador no feed?')) {
            const upsertResult = await createNewEmployeePostMutation({ 
                variables: {
                    employeeId: employeeId,
                    content: content
                },
            })
            await existingPostRefetch();

            if(upsertResult.data) {
                toast.success(`Post publicado com sucesso`)
            }
        }
    }

    const deleteEmployeeSkill = async (id) => {
        if (window.confirm('Tem a certeza que pretende eliminar a competência?')) {
            const deleteResult = await deleteEmployeeSkillMutation({ 
                variables: {
                    id
                },
            })
            await refetch();

            if(deleteResult.data) {
                toast.success('Competência eliminada com sucesso!')
            }
        }
    }
    
    const isCurrentUser = data?.EmployeeById?.user?.externalId === currentUser?.sub;

    const directReports = (data && data?.EmployeeById?.directReports?.filter(e => e.isActive).slice().sort((a, b) => a.name.localeCompare(b.name))) || [];
    const skills = (data && data?.EmployeeById?.skills?.slice().sort((a, b) => a.name.localeCompare(b.name))) || [];

    return (
        <Fragment> 
            <AdminHeader />
            <Leftnav />

            <div className="main-content">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0" style={{maxWidth:`100%`}}>
                        <div className="row">
                            <div className="col-lg-12">
                                <CustomLoadingOverlay active={loading} />
                                <div className="chat-wrapper p-0 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                                    <div className="chat-wrapper pt-0 w-100 position-relative scroll-bar">
                                        <div className="chat-body p-lg-4 p-3 mt-lg-3 mt-0">
                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mb-3 shadow-none">
                                                <div className="card-body p-0">
                                                    <h6 className="fw-600 text-grey-500 font-xssss">Colaborador</h6>
                                                    <h2 className="font-xl text-grey-900 fw-600">{data?.EmployeeById?.fullName}</h2>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome Completo
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.fullName}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome de Apresentação
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.name}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">ID Externo
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.externalId}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.email}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">UPN
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.upn}</span></h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Sexo
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{PERSON_GENDER_OPTIONS.filter(o => o.id == data?.EmployeeById?.gender)[0]?.name}</span></h4>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Nascimento
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.birthdate && <Moment format="DD/MM/YYYY">{data?.EmployeeById?.birthdate}</Moment>}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Telefone
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.phone}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Telemóvel
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.mobile}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Telemóvel/Contacto Alternativo
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.mobilePersonal}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Empresa
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.company?.name}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Departamento
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.department?.name}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Função
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.jobTitle}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Superior Hierárquico
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.manager?.name}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Admissão
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.hireDate && <Moment format="DD/MM/YYYY">{data?.EmployeeById?.hireDate}</Moment>}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Rescisão
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.terminationDate && <Moment format="DD/MM/YYYY">{data?.EmployeeById?.terminationDate}</Moment>}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <i className={`${data?.EmployeeById?.isExternal ? 'feather-check text-success' :'feather-x text-danger'} me-3 font-md`}></i>
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Colaborador Externo? 
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.isExternal ? 'Sim' :'Não'}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <i className={`${data?.EmployeeById?.hideFromOrgChart ? 'feather-eye-off text-danger' :'feather-eye text-success'} me-3 font-md`}></i>
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Ocultar do Organograma? 
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.hideFromOrgChart ? 'Oculto' :'Visível'}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <i className={`${data?.EmployeeById?.isActive ? 'feather-check text-success' :'feather-x text-danger'} me-3 font-md`}></i>
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Ativo? 
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.isActive ? 'Sim' :'Não'}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                <form className="d-block overflow-hidden">
                                                    { false && <button className="bg-dark border-0 btn-round-md float-left d-lg-block d-none"><i className="ti-microphone text-white lh-4 font-xs"></i></button> }
                                                    { false && <button className="bg-dark border-0 btn-round-md ms-1 float-left"><i className="ti-clip text-white lh-4 font-xs"></i></button> }
                                                    { false && <button className="bg-dark border-0 btn-round-md ms-1 float-left"><i className="ti-image text-white lh-4 font-xs"></i></button> }
                                                    { data?.EmployeeById?.isActive && existingPostData?.PostList?.length == 0 && <a role="button" className={`p-2 ps-3 pe-3 lh-20 bg-success border-0 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-1 float-left`} onClick={() => createNewEmployeePost(data?.EmployeeById?.id, "")}>Publicar Entrada no Feed</a> }
                                                    { data?.EmployeeById && !isCurrentUser && <a role="button" className={`p-2 ps-3 pe-3 lh-20 ${data.EmployeeById.isActive ? 'bg-danger' : 'bg-success'} border-0 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-1 float-left`} onClick={() => updateEmployeeStatus(!data.EmployeeById.isActive)}> { data.EmployeeById.isActive ? 'Desativar' : 'Ativar'} Colaborador</a> }
                                                    <Link to={`/admin/employees/edit/${data?.EmployeeById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>
                                                </form>
                                            </div>

                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 mb-2 shadow-none">
                                                <div className="card-body p-0">
                                                    <h6 className="fw-600 text-grey-500 font-xs">Utilizador</h6>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Nome de Utilizador
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.user?.username}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Email
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.user?.email}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <i className={`${data?.EmployeeById?.user?.isActive ? 'feather-check text-success' :'feather-x text-danger'} me-3 font-md`}></i>
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Ativo? 
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.user?.isActive ? 'Sim' :'Não'}</span></h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Perfil
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{userData?.UserIdentity?.realmRoles?.map(r => USER_ROLES.filter(ur => ur.id === r.id)[0]?.name)}</span></h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div className="d-block pt-1">
                                                        <h4 className="fw-700 text-grey-900 font-xsss mt-0">Data de Registo
                                                        <span className="d-block font-xsss fw-500 mt-1 lh-3 text-grey-500">{data?.EmployeeById?.user?.createdDate && <Moment format="DD/MM/YYYY HH:mm">{data?.EmployeeById?.user?.createdDate}</Moment>}</span></h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="dark-bg pt-3 pb-3 mb-3 border-bottom bor-0 theme-dark-bg w-100">
                                                <form className="d-block overflow-hidden">
                                                    { false && <button className="bg-dark border-0 btn-round-md float-left d-lg-block d-none"><i className="ti-microphone text-white lh-4 font-xs"></i></button> }
                                                    { false && <button className="bg-dark border-0 btn-round-md ms-1 float-left"><i className="ti-clip text-white lh-4 font-xs"></i></button> }
                                                    { false && <button className="bg-dark border-0 btn-round-md ms-1 float-left"><i className="ti-image text-white lh-4 font-xs"></i></button> }
                                                    { data?.EmployeeById?.user && !isCurrentUser && <a role="button" className={`p-2 ps-3 pe-3 lh-20 bg-dark border-0 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-1 float-left`} onClick={() => setRoleModalShow(true)}> Alterar Perfil</a> }
                                                    { data?.EmployeeById?.user && <a role="button" className={`p-2 ps-3 pe-3 lh-20 bg-dark border-0 text-white text-center font-xssss fw-600 ls-1 rounded-xl ms-1 float-left`} onClick={() => setPasswordModalShow(true)}> Alterar Password</a> }
                                                    { false && <Link to={`/admin/employees/edit/${data?.EmployeeById?.id}`} className="border-0 float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl">Editar</Link>}
                                                </form>
                                            </div>
                                            
                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                <div className="card-body p-0">
                                                    <h3 className="font-md text-grey-900 fw-600">Competências</h3>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <a role="button" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setAddSkillModalShow(true)}>Adicionar Competência</a>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ skills.length } resultados</h4>
                                                    { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                                </div>
                                            </div>

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-2 text-left">Competência</th>
                                                            <th className="border-0 p-2 px-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {skills.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-left wide-column">
                                                                <span className="text-grey-900 fw-600 font-xsss">{value.skill?.name}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <a role="button" onClick={() => deleteEmployeeSkill(value.id)}><i className="ti-trash font-xs text-grey-500"></i></a>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                <div className="card-body p-0">
                                                    <h3 className="font-md text-grey-900 fw-600">Reportes Diretos</h3>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12 mb-3">
                                                    <h4 className="float-left font-xssss fw-700 text-grey-500 text-uppercase ls-3 mt-2 pt-1">{ directReports.length } resultados</h4>
                                                    { false && <select className="searchCat float-right sort"> <option value="">Default Sorting</option><option value="151781441596 ">Fashion</option><option value="139119624252 ">- Men</option><option value="139118313532 ">- Women</option><option value="139360141372 ">Electronics</option><option value="152401903676 ">Home &amp; Garden</option><option value="138866720828 ">- Decor</option><option value="138866917436 ">- Lighting</option></select> }
                                                </div>
                                            </div>

                                            <div className="table-content table-responsive">
                                                <table className="table text-center">
                                                    <thead className="bg-greyblue rounded-3">
                                                        <tr>
                                                            <th className="border-0 p-2">&nbsp;</th>
                                                            <th className="border-0 p-2 text-left">Nome</th>
                                                            <th className="border-0 p-2 px-4">Empresa</th>
                                                            <th className="border-0 p-2 px-4">Departamento</th>
                                                            <th className="border-0 p-2 px-4">Função</th>
                                                            <th className="border-0 p-2 px-4">&nbsp;</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {directReports.map((value, index) => (
                                                        <tr key={value.id}>
                                                            <td className="text-center ps-0">
                                                                <img src={getProfilePicture(value)} alt={value.name} className="w35 rounded-circle aspect-ratio-1" />
                                                            </td>
                                                            <td className="text-left wide-column">
                                                                <span className="text-grey-900 fw-600 font-xsss">
                                                                    <Link to={`/admin/employees/${value.id}`}>{value.name}</Link>
                                                                    { value.isExternal && <span className={`p-1 ps-2 pe-2 text-warning alert-warning font-xsssss rounded-3`}><strong>Externo</strong></span> }
                                                                </span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.company?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.department?.name}</span>
                                                            </td>
                                                            <td className="text-center">
                                                                <span className="text-grey-900 font-xsss">{value.jobTitle}</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <Link to={`/admin/employees/edit/${value.id}`}><i className="ti-pencil font-xs text-grey-500"></i></Link>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>


                                            <div className="card dark-bg-transparent border-0 w-100 p-0 mt-5 shadow-none">
                                                <div className="card-body p-0">
                                                    <h3 className="font-md text-grey-900 fw-600">Wallet</h3>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <a role="button" className="float-right p-2 ps-3 pe-3 lh-20 bg-primary-gradiant me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl" onClick={() => setTopupWalletModalShow(true)}>Adicionar Saldo</a>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3 mb-5">
                                                    <div className="col-lg-12 ps-0">
                                                        <h4 className="mont-font fw-600 font-sm mb-2 p-2">Saldo</h4>
                                                    </div>
                                                    <div className="cleafrfix"></div>
                                                    { data?.EmployeeById?.user?.wallet &&
                                                        <CreditCard bank="empathize" holder={data?.EmployeeById?.name} balance={data?.EmployeeById?.user?.wallet?.balance} currencySymbol={<i className="icon-empathize text-success"></i>} />
                                                    }
                                                </div>
                                                <div className="col-lg-8 offset-lg-1">
                                                    <h4 className="mont-font fw-600 font-sm mb-2 p-2">Últimos movimentos</h4>
                                                    <div className="table-content table-responsive border-0 p-2">
                                                        <table className="table text-center">
                                                            <thead className="bg-greyblue rounded-3">
                                                                <tr>
                                                                    <th className="border-0">Data</th>
                                                                    <th className="border-0">Descrição</th>
                                                                    <th className="text-right border-0">Valor</th>
                                                                    <th className="text-right border-0">Saldo</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {data?.EmployeeById?.user?.wallet?.transactions?.slice()
                                                                .sort(function (a, b) {
                                                                    const d1 = new Date(a.createdDate).getTime()
                                                                    const d2 = new Date(b.createdDate).getTime()
                                                                    return d2 - d1;
                                                                })
                                                                .slice(0, 20)
                                                                .map((transaction, index) => (
                                                                <tr key={index}>
                                                                    <th className="text-grey-500 fw-500 font-xsss"><Moment format="DD/MM/YYYY">{transaction.createdDate}</Moment></th>
                                                                    <th className="text-grey-500 fw-500 font-xsss">{transaction.description}</th>
                                                                    <td className={`text-right fw-500 font-xsss ${transaction.amount > 0 ? ' text-success ' : ' text-grey-500 '}`}>{transaction.amount} <i className="icon-empathize-square ms1" /></td>
                                                                    <td className="text-grey-500 text-right fw-500 font-xsss">{transaction.finalBalance} <i className="icon-empathize-square ms1" /></td>
                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Appfooter />

            <UpdatePasswordModal show={passwordModalShow} onHide={() => setPasswordModalShow(false)} user={data?.EmployeeById?.user} />
            <UpdateRoleModal
                show={roleModalShow}
                onHide={() => { 
                    setRoleModalShow(false);
                    userRefetch();
                }}
                user={data?.EmployeeById?.user}
            />
            <TopupWalletModal
                show={topupWalletModalShow}
                onHide={async () => { 
                    setTopupWalletModalShow(false);
                    refetch();
                }}
                user={data?.EmployeeById?.user}
            />

            <AddEmployeeSkillModal
                show={addSkillModalShow}
                onHide={async () => { 
                    setAddSkillModalShow(false);
                    refetch();
                }}
                employee={data?.EmployeeById}
            />

        </Fragment>
    );
}

export default AdminEmployee;